<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="contactData === undefined">
      <h4 class="alert-heading">Errore dati Contatto</h4>
      <div class="alert-body">
        Nessun Contatto trovato con questo ID. Controlla
        <b-link class="alert-link" :to="{ name: 'apps-contacts-list' }"> Lista Contatti </b-link>
        per altri Contatti.
      </div>
    </b-alert>

    <template v-if="contactData" pills>
      <!-- Tab: Information -->
      <b-row>
        <b-col cols="12">
          <contacts-edit-tab-information :contact-data="contactData"/>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BCard, BAlert, BLink, BCol, BRow } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import contactsStoreModule from '../contactsStoreModule'
import ContactsEditTabInformation from './ContactsEditTabInformation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BCol,
    BRow,

    ContactsEditTabInformation,
  },
  setup() {
    const toast = useToast()

    const contactData = ref(null)

    const CONTACTS_APP_STORE_MODULE_NAME = 'app-contacts'

    // Register module
    if (!store.hasModule(CONTACTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(CONTACTS_APP_STORE_MODULE_NAME, contactsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACTS_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTACTS_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-contacts/fetchContact', { id: router.currentRoute.params.id })
      .then((response) => {
        contactData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          router
            .replace({ name: 'auth-login' })
            .then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
            .catch(() => {})
        }
      })

    return {
      contactData
    }
  },
}
</script>

<style></style>
